import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { formEvent, trackleadform } from "@/analytics/analytics";

import { createHubspotLead } from "@/client/OneistoxApiClient";

import Box from "@/design-system/Box";
import PrimaryButton from "@/design-system/Button/PrimaryButton";
import TextField from "@/design-system/Form/TextField";
import Icon from "@/design-system/Icon";
import Typography from "@/design-system/Typography";

import { useAppSelector } from "@/hooks/hooks";
import useScreenDuration from "@/hooks/useScreenDuration";

import { PreviousRouteProvider } from "@/pages/_app";

import { emailValidator } from "@/utility/validator";

import { getScrollPositionByPercentage } from "@/utils/utils";

import { theme } from "@/theme";

const LinkComp = styled(Typography)`
  &:hover {
    color: ${theme.colors.gray[900]};
  }
`;

const RightItemStyle = styled.div`
  grid-column: span 12;
  grid-row: 1;
  margin-bottom: 92px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: 8 / span 5;
    grid-row: unset;
    margin-bottom: 0;
    padding-left: 32px;
  }
`;

const InputStyle = styled(TextField)`
  min-height: 48px;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-height: 56px;
  }
`;

const GridStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  margin-bottom: 40px;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 250px 150px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 96px;
    grid-template-columns: 1fr auto;
  }
`;

const GridStyleMenuContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }
`;

const FooterRightItem = ({ isRow }) => {
  const router = useRouter();
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const [inputFocus, setInputFocus] = useState(false);
  const time = useScreenDuration();
  const { previousRoute } = useContext(PreviousRouteProvider);
  const loginDetails = useAppSelector((state) => state?.rootReducer?.auth);

  const [subscribed, setSubscribed] = useState({} as any);
  useEffect(() => {
    window.onbeforeunload = () => {
      if (inputFocus) {
        window.dataLayer?.push({
          event: "formAbandonment",
          eventCategory: "Form Abandonment",
          eventAction: "->email",
          "gtm.elementId": "newsLetter",
          "gtm.formId": "newsLetter",
        });
        const mixPanelTrackObj = {
          Page_path: window.location.pathname,
          Current_page: window.location.href,
          Page_Referrer: previousRoute,
          percent_scrolled: getScrollPositionByPercentage(),
          on_screen_duration: time.formattedTime,
          formId: "join_our_newsletter",
          isLoggedIn: loginDetails.isAuthenticated,
        };
        formEvent("newsletter", mixPanelTrackObj);
      }
    };
  }, [inputFocus]);

  useEffect(() => {
    const handler = () => {
      if (inputFocus) {
        const newDta = {
          pageReferrer: previousRoute,
          scrollPercentage: getScrollPositionByPercentage(),
          screenDuration: time.formattedTime,
          currentPage: window.location.href,
          pagePath: window.location.pathname,
          formId: "request_a_call_back",
          isLoggedIn: loginDetails.isAuthenticated,
        };
        formEvent("Form_abondonment", newDta);
      }
    };
    router.events.on("routeChangeStart", handler);
    return () => {
      router.events.off("routeChangeStart", handler);
    };
  }, [inputFocus]);

  useEffect(() => {
    if (inputFocus) {
      const newDta = {
        pageReferrer: previousRoute,
        scrollPercentage: getScrollPositionByPercentage(),
        screenDuration: time.formattedTime,
        currentPage: window.location.href,
        pagePath: window.location.pathname,
        formId: "join_our_newsletter",
        isLoggedIn: loginDetails.isAuthenticated,
      };
      formEvent("Form_start", newDta);
    }
  }, [inputFocus]);

  const submitHandler = async () => {
    if (!emailValidator(input)) {
      setError(true);
    } else {
      try {
        const data = {
          event_type: "Subscribed",
          formUrl: window.location.href,
          tokens: {
            accountCreated: false,
            lifecyclestage: "subscriber",
          },
          extraData: {},

          pageUrl: window.location.href,
          landingPageUrl: sessionStorage.getItem("initial_landing_page"),
          source: router?.query?.utm_source,
          formId: "footer_newsletter",
        };
        const mixPanelTrackObj = {
          email: input,
          Page_path: window.location.pathname,
          Current_page: window.location.href,
          Page_Referrer: previousRoute,
          percent_scrolled: getScrollPositionByPercentage(),
          on_screen_duration: time.formattedTime,
          loginDetails: loginDetails.userData,
          isLoggedIn: loginDetails.isAuthenticated,
        };
        trackleadform("newsletter", mixPanelTrackObj);
        window.dataLayer?.push({
          event: "gtm.formSubmit",
          "gtm.elementId": "newsletter",
          "gtm.formId": "newsletter",
          "gtm.triggers": "",
        });
        setCanSubmit(false);
        const res = await createHubspotLead(data, input);
        setCanSubmit(true);

        setSubscribed({ ...subscribed, status: res.status, res: res.data });
      } catch (error) {
        console.log(error);
        setSubscribed({ ...subscribed, status: 409, res: "Already Exists" });
      }
      setError(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    if (emailValidator(e.target.value)) {
      setError(false);
    }
    setSubscribed({});
  };

  return (
    <RightItemStyle>
      <Box borderRadius={"16px"}>
        <Typography
          varient="heading4"
          weightVarient="semibold"
          mb={1}
          color={theme.colors.primary[900]}
        >
          Join our newsletter
        </Typography>
        <Typography
          color={theme.colors.gray[600]}
          mb={"24px"}
          varient="bodys"
          weightVarient="medium"
        >
          We'll send you a nice letter once per month. No spam.
        </Typography>

        <GridStyle>
          <Box>
            <InputStyle
              name="email"
              value={input}
              mb={"0px"}
              onFocus={() => {
                setInputFocus(true);
              }}
              size="lg"
              placeholder="Enter your email"
              icon={
                <Icon type={"mail-01"} color={theme.colors.gray[500]} varient={"line"} size={20} />
              }
              // value={input}
              id="form_email"
              onChange={handleChange}
            />
          </Box>
          <Box>
            <PrimaryButton
              fullWidth
              size="lg"
              label={canSubmit ? "Subscribe" : "Please wait..."}
              className="button__subscribe"
              onClick={submitHandler}
              id="form_submit"
              disabled={!/\S+@\S+\.\S+/.test(input) || !canSubmit}
            />
          </Box>
          {error && (
            <Box>
              <Typography color={theme.colors.error[500]}>
                Please Enter Valid Email Address
              </Typography>
            </Box>
          )}
          {subscribed.res && (
            <Box>
              <Typography color={theme.colors.success[500]}>Thank you for signing up.</Typography>
            </Box>
          )}
          {subscribed.status === 409 && (
            <Typography varient="bodyxs">Already Subscribed</Typography>
          )}
        </GridStyle>
      </Box>

      <GridStyleMenuContainer>
        <Box p={"8px"}>
          <Typography
            mb={"24px"}
            varient="labels"
            weightVarient="medium"
            color={theme.colors.gray[500]}
          >
            COMPANY
          </Typography>
          <Link className="link__" href={"/about"}>
            <LinkComp
              mb={"8px"}
              varient="bodyxs"
              weightVarient="semibold"
              color={theme.colors.gray[700]}
            >
              About Us
            </LinkComp>
          </Link>
          <Link className="link__" href={"/courses/building-information-modelling"}>
            <LinkComp
              mb={"8px"}
              varient="bodyxs"
              weightVarient="semibold"
              color={theme.colors.gray[700]}
            >
              {isRow
                ? "PGP in Building Information Modelling (BIM) for Architects"
                : "BIM Professional Course for Architects V2.0"}
            </LinkComp>
          </Link>
          <Link className="link__" href={"/courses/computational-design"}>
            <LinkComp
              mb={"8px"}
              varient="bodyxs"
              weightVarient="semibold"
              color={theme.colors.gray[700]}
            >
              {isRow
                ? "PGP in Parametric Design for Architects"
                : "Master Computational Design Course"}
            </LinkComp>
          </Link>
          <Link className="link__" href={"/courses/bim-for-civil-engineers"}>
            <LinkComp
              mb={"8px"}
              varient="bodyxs"
              weightVarient="semibold"
              color={theme.colors.gray[700]}
            >
              {isRow
                ? "PGP in Building Information Modelling (BIM) for Civil Engineers"
                : "BIM Professional Course For Civil Engineers"}
            </LinkComp>
          </Link>
          <Link className="link__" href={"/apply-as-mentor"}>
            <LinkComp
              mb={"8px"}
              varient="bodyxs"
              weightVarient="semibold"
              color={theme.colors.gray[700]}
            >
              Become a Mentor
            </LinkComp>
          </Link>
          <Link className="link__" href={"/hire-from-us"}>
            <LinkComp
              mb={"8px"}
              varient="bodyxs"
              weightVarient="semibold"
              color={theme.colors.gray[700]}
            >
              Hiring Partners
            </LinkComp>
          </Link>
          <Link className="link__" href={"/career"}>
            <LinkComp
              mb={"8px"}
              varient="bodyxs"
              weightVarient="semibold"
              color={theme.colors.gray[700]}
            >
              Careers at Novatr
            </LinkComp>
          </Link>
          <Link
            className="link__"
            target="_blank"
            href={"https://www.novatr.com/media-room-home-page"}
          >
            <LinkComp
              mb={"8px"}
              varient="bodyxs"
              weightVarient="semibold"
              color={theme.colors.gray[700]}
            >
              Media Room
            </LinkComp>
          </Link>
        </Box>

        <Box p={"8px"}>
          <Typography
            mb={"24px"}
            varient="labels"
            weightVarient="medium"
            color={theme.colors.gray[500]}
          >
            RESOURCES
          </Typography>
          <Link className="link__" href={"/learning-hub"}>
            <LinkComp
              mb={"8px"}
              varient="bodyxs"
              weightVarient="semibold"
              color={theme.colors.gray[700]}
            >
              Blog
            </LinkComp>
          </Link>

          <Link className="link__" href={"/webinars"}>
            <LinkComp
              mb={"8px"}
              varient="bodyxs"
              weightVarient="semibold"
              color={theme.colors.gray[700]}
            >
              Events & Webinars
            </LinkComp>
          </Link>

          <Link className="link__" href={"/impact-on-aec"}>
            <LinkComp
              mb={"8px"}
              varient="bodyxs"
              weightVarient="semibold"
              color={theme.colors.gray[700]}
            >
              Our Impact
            </LinkComp>
          </Link>

          <Link className="link__" href={{ pathname: "/contact-us" }}>
            <LinkComp
              mb={"8px"}
              varient="bodyxs"
              weightVarient="semibold"
              color={theme.colors.gray[700]}
            >
              Contact Us
            </LinkComp>
          </Link>
        </Box>
      </GridStyleMenuContainer>
    </RightItemStyle>
  );
};

export default FooterRightItem;
