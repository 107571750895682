import styled from "styled-components";

import Container from "@/design-system/Container";
import GridContainer from "@/design-system/GridContainer";

import { theme } from "@/theme";

import FooterBar from "./FooterInfoBar";
import FooterLeftItem from "./FooterLeftItem";
import FooterRightItem from "./FooterRightItem";

const FooterBox = styled.footer`
  padding-top: 48px;
  padding-bottom: 64px;
  background-color: ${theme.colors.grayWarm[100]};
`;

const GridContainerStyle = styled(GridContainer)`
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-left: 32px;
    padding-right: 40px;
  }
`;

const GrayBox = styled.div`
  position: absolute;
  display: none;
  right: 0;
  width: 50%;
  height: 100%;
  top: 0;
  z-index: 0;
  background-color: ${theme.colors.grayWarm[50]};
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: block;
  }
`;

const Footer = ({ disableCta, isRow }: { disableCta: boolean; isRow?: boolean }) => {
  return (
    <>
      <FooterBox>
        {!disableCta && <GrayBox />}
        <Container>
          <GridContainerStyle>
            <FooterLeftItem isRow={isRow} disableCta={disableCta} />
            {!disableCta && <FooterRightItem isRow={isRow} />}
          </GridContainerStyle>
        </Container>
      </FooterBox>
      <FooterBar isRow={isRow} />
    </>
  );
};

export default Footer;
